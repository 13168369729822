export const _360ProvidersLinks = {
  Matterport: "my.matterport.",
  Openspace: "openspace.",
  Holobuilder: "app.holobuilder.",
  Dronedeploy: "Dronedeploy",
  Evercam: "360.evercam.",
}
export const _360ProvidersParams = {
  Matterport: "show?m=",
  Openspace: "external/org",
  Holobuilder: "app?p=",
  Dronedeploy: "",
  Evercam: "?project_id=",
}

export const _360Providers = {
  Matterport: "Matterport",
  Openspace: "Openspace",
  Holobuilder: "Holobuilder",
  Dronedeploy: "Dronedeploy",
  Evercam: "Evercam",
}

export const _360ProvidersSuffixes = {
  Matterport: "com",
  Openspace: "ai",
  Holobuilder: "com",
  Dronedeploy: "com",
  Evercam: "io",
}
